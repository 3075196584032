<template>
  <div class="content-footer d-flex-center py-1 px-0 px-lg-4">
    <!-- ANCHOR - Mặt vé -->
    <b-button
      v-if="bookingData"
      class="px-50 px-md-2 mx-50 mx-md-1"
      variant="warning"
      pill
      @click="showPopupTicketFace(bookingData.id)"
    >
      <span class="text-nowrap text-truncate">{{ $t('reservation.exportTicket') }}</span>
    </b-button>

    <!-- ANCHOR - THANH TOÁN -->
    <b-button
      v-if="bookingData"
      class="px-50 px-md-2 mx-50 mx-md-1"
      variant="danger"
      pill
      :disabled="isDisabledPayment"
      @click="openPaymentFlightModalHandle(bookingData)"
    >
      <span class="text-nowrap text-truncate">{{ $t('reservation.payment') }}</span>
    </b-button>
    <ModalPaymentTrainTicket :bookingData="bookingData" />
  </div>
</template>
<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

import { STATUS_TRAIN } from '@/constants/train'

import ModalPaymentTrainTicket from './ModalPaymentTrainTicket.vue'

export default {
  components: {
    BButton,
    ModalPaymentTrainTicket,
  },
  directives: {
    'v-b-tooltip': VBTooltip,
  },
  props: {
    bookingData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    function openPaymentFlightModalHandle() {
      this.$bvModal.show('modal-payment-train-ticket')
    }

    const isDisabledPayment = computed(() => ![STATUS_TRAIN.HOLD, STATUS_TRAIN.PROCESSING].includes(props.bookingData.status))

    async function showPopupTicketFace(id) {
      const route = this.$router.resolve({
        name: 'apps-train-ticketFace',
        params: { id },
      })
      window.open(route.href)
    }

    return {
      openPaymentFlightModalHandle,
      isDisabledPayment,
      showPopupTicketFace,
    }
  },
}
</script>
<style lang="scss" scoped>
.content-footer {
  position: sticky;
  bottom: 0;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  border-radius: 8px;
}
</style>
