<template>
  <!-- SECTION modal-payment-train-ticket -->
  <b-modal
    id="modal-payment-train-ticket"
    :title="$t('reservation.payment')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    ok-variant="primary"
    no-close-on-backdrop
    no-close-on-esc
    size="lg"
    no-enforce-focus
    @show="handleOpenModal"
  >
    <IAmOverlay
      :loading="loading || isLoading"
      spinner-variant="primary"
    >
      <b-row>
        <!-- ANCHOR - Agency-->
        <b-col cols="12">
          <b-form-group>
            <template #label>
              <div class="d-flex-between">
                <span class="text-dark font-weight-bolder">{{ $t('employee.agencies') }}</span>
                <!-- <BButton
                    variant="flat-dark"
                    class="p-25 rounded-circle"
                    @click="handleOpenModal"
                  >
                    <IAmIcon
                      icon="revisionOutline"
                      size="20"
                    />
                  </BButton> -->
              </div>
            </template>

            <v-select
              v-model="agencyManager"
              :options="agenciesManagerList"
              label="agencyName"
              searchable
              :clearable="false"
              :filterable="false"
              input-id="agency"
              class="agency_select"
              :disabled="true"
              :placeholder="$t('placeholderSelect')"
              @open="openAgenciesManager"
              @search="searchAgenciesManager"
            >
              <template #selected-option="data">
                <div class="d-flex-center justify-content-between">
                  <span class="font-weight-bolder d-block text-nowrap">
                    {{ data.agencyName }}
                  </span>
                </div>
              </template>

              <template #option="data">
                <div class="d-flex-center justify-content-between">
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ data.agencyName }}
                  </span>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ data.agencyCode }}
                  </span>
                </div>
              </template>

              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>

              <template #no-options>
                {{ $t('flight.noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- ANCHOR - Price -->
        <b-col
          v-if="agencyManager"
          cols="12"
          class="text-dark"
        >
          <b-row class="mb-1">
            <b-col cols="4">
              <span>
                {{ $t('reservation.agencyName') }}:
              </span>
            </b-col>
            <b-col
              cols="8"
              class="text-right"
            >
              <span class="text-airline font-weight-bold">
                {{ agencyManager.agencyName }} (<span class="text-uppercase">{{ agencyManager.agencyCode }}</span>)
              </span>
            </b-col>
          </b-row>

          <b-row
            v-if="!isMamaAgency"
            class="mb-50"
          >
            <b-col
              cols="6"
              class="d-flex-center justify-content-start"
            >
              <span>
                {{ $t('reservation.balanceLimit') }}
                <span
                  v-if="(resCheckCredit?.lockBalance)"
                  class="text-danger"
                > + {{ $t('lockBalance') }}</span>
                <span
                  v-if="showLimitCredit"
                  class="text-nowrap"
                > / {{ $t('reservation.limitCredit') }}</span>
                :
              </span>
            </b-col>
            <b-col
              cols="6"
              class="text-right d-flex-center justify-content-end"
            >
              <BButton
                v-b-tooltip.hover.v-info
                :title="`${$t(showLimitCredit ? 'hide' : 'show')} ${$t('reservation.limitCredit')}`"
                variant="flat-info"
                class="p-50 rounded-circle"
                @click="handleChangeShowLimitCredit"
              >
                <feather-icon
                  class="cursor-pointer"
                  :icon="showLimitCredit ? 'EyeIcon' : 'EyeOffIcon'"
                />
              </BButton>
              <span v-if="resCheckCredit">
                {{ formatCurrency(resCheckCredit.balance) }}
                <span
                  v-if="(resCheckCredit?.lockBalance)"
                  v-b-tooltip.hover.v-danger
                  class="text-danger"
                  :title="$t('lockBalance')"
                > + {{ formatCurrency(resCheckCredit?.lockBalance) }}</span>
                <span v-if="showLimitCredit"> / {{ formatCurrency(resCheckCredit.limitCredit) }}</span>
              </span>
              <span v-else>
                0
              </span>
              <b-button
                v-if="resCheckCredit?.lockBalance && resCheckCredit?.lockedList && resCheckCredit?.lockedList.length > 0"
                v-b-tooltip.hover.v-dark
                variant="flat-dark"
                class="p-25 rounded-circle ml-25"
                :title="$t('reservation.lockedList.title')"
                @click="handleShowLockedList"
              >
                <IAmIcon
                  icon="dotsOutline"
                  size="18"
                />
              </b-button>
            </b-col>
          </b-row>

          <!-- ANCHOR Tổng giá vé -->
          <b-row
            v-if="isShowPriceTicket"
            class="mb-50"
          >
            <b-col cols="6">
              <span>
                {{ $t('reservation.ticketPrice') }}:
              </span>
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              <b-spinner
                v-if="!totalToBePay && totalToBePay !== 0"
                variant="info"
                style="width: 20px; height: 20px;"
              />
              <span v-else>
                {{ formatCurrency(totalToBePay) }}
              </span>
            </b-col>
          </b-row>

          <!-- ANCHOR Giảm giá -->
          <!-- <b-row
            v-if="isShowPriceTicket"
            class="mb-50"
          >
            <b-col cols="4">
              <span>
                {{ $t('reservation.discount') }}:
              </span>
            </b-col>
          </b-row>
          <b-row
            v-if="promotionAmountAfter"
            class="mb-50"
          >
            <b-col cols="6">
              <span>
                {{ $t('reservation.discountAfter') }}:
              </span>
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              <span>
                {{ formatCurrency(promotionAmountAfter) }}
              </span>
            </b-col>
          </b-row> -->

          <b-row class="mb-50 font-weight-bolder">
            <b-col cols="6">
              <span>
                {{ $t('reservation.totalAmountNeedToPay') }}:
              </span>
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              <span v-if="totalPriceFare || totalPriceFare === 0">
                {{ formatCurrency(totalPriceFare) }}
              </span>
              <b-spinner
                v-else
                variant="info"
                style="width: 20px; height: 20px;"
              />
            </b-col>
          </b-row>

          <div v-if="!isMamaAgency && resCheckCredit">
            <hr class="font-weight-bolder">
            <b-row class="font-weight-bolder mb-50">
              <b-col cols="6">
                <span>
                  {{ $t('reservation.balanceAfterPayment') }}:
                </span>
              </b-col>
              <b-col
                cols="6"
                class="text-right text-16px"
                :class="resCheckCredit ? `text-${(resCheckCredit.isOverLimit || resCheckCredit.isOverLimitParent || resCheckCredit.isWarningLimitPercent) ? 'danger' : ((resCheckCredit.isWarning || resCheckCredit.isWarningLimitPercent) ? 'warning' : 'success')}`: ''"
              >
                <span>
                  {{ formatCurrency(Number(resCheckCredit.balance) + Number(totalPriceFare)) }}
                </span>
              </b-col>
            </b-row>
          </div>

          <!-- ANCHOR - Show Alert -->
          <b-row
            v-if="resCheckCredit && (resCheckCredit.isOverLimit || resCheckCredit.isOverLimitParent || resCheckCredit.isWarningLimitPercent || resCheckCredit.isWarning)"
            class="mb-50"
          >
            <b-col
              v-if="(resCheckCredit.isOverLimit || resCheckCredit.isWarningLimitPercent)"
              cols="12"
              class="text-center"
            >
              <b-alert
                show
                variant="danger"
                class="py-1 text-title my-25"
              >
                <div
                  v-html="resCheckCredit.isOverLimit
                    ? $t('reservation.paymentOverLimit', { agencyCode: agencyString })
                    : resCheckCredit.isWarningLimitPercent
                      ? $t('reservation.isWarningLimitPercent', { agencyCode: agencyString, percent: resCheckCredit.warningLimitPercent })
                      : $t('reservation.paymentWarning', { agencyCode: agencyString })"
                />
              </b-alert>
            </b-col>

            <b-col
              v-else-if="resCheckCredit.isWarning"
              cols="12"
              class="text-center"
            >
              <b-alert
                show
                variant="warning"
                class="py-1 text-title my-25"
              >
                <div v-html="$t('reservation.paymentWarning', { agencyCode: agencyString })" />
              </b-alert>
            </b-col>

            <b-col
              v-if="resCheckCredit.isOverLimitParent"
              cols="12"
              class="text-center"
            >
              <b-alert
                show
                variant="danger"
                class="py-1 text-title my-25"
              >
                <div v-html="$t('reservation.isOverLimitParent', { agencyCode: agencyParentString })" />
              </b-alert>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </IAmOverlay>

    <template #modal-footer="{ cancel }">
      <b-button
        size="md"
        variant="outline-secondary"
        class="center rounded-pill"
        @click="cancel()"
      >
        {{ $t('reservation.close') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient border-right-0"
        pill
        @click="confirmPayment(bookingData)"
      >
        <div class="d-flex-center px-25">
          {{ $t('reservation.payment') }}
        </div>
      </b-button>
    </template>
  </b-modal>
  <!-- !SECTION -->
</template>

<script>
import {
  BButton, BAlert, VBTooltip, BSpinner, BModal, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import {
  ref, computed, toRefs,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import { debounce, isEmpty } from 'lodash'
import Vue from 'vue'

import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'
import { apiAgencies } from '@/api/'
import store from '@/store'
import IAmPaymentPassword from '@/components/IAmPaymentPassword.vue'
import i18n from '@/libs/i18n'

import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'
import { validatorPaymentPassword } from '@core/utils/validations/validators'

import useToast from '@useToast'

import useTrainTicketDetailHandle from '../useTrainTicketDetailHandle'

export default {
  components: {
    BModal,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BSpinner,
    vSelect,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    bookingData: {
      type: Object,
      default: null,
    },
  },

  // SECTION setup
  setup(props) {
    const { toastError } = useToast()
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const getAgencyData = computed(() => store.getters['userStore/getAgencyData'])

    const { paymentTrains, loading } = useTrainTicketDetailHandle()
    const tablePriceQuotesColumn = [
      { label: '', key: 'checkbox' },
      { label: 'fullName', key: 'full_name' },
      { label: 'itinerary', key: 'itinerary' },
      { label: 'fareBasisCode', key: 'fare_basic' },
      { label: 'price', key: 'price' },
    ]
    const agencyManager = ref(null)
    // const isDisabledPayment = ref(true)
    const resCheckCredit = ref(null)
    const isLoading = ref(false)
    const bookingData = toRefs(props).bookingData

    // F1 xuất over cho F2, F3; F2 xuất over cho F3
    const isOverPayment = ref(false)

    const specifiedPassengers = ref([])

    const isMamaAgency = computed(() => {
      let result = false
      if (agencyManager.value && agencyManager.value.id === 1000000) {
        result = true
      }
      return result
    })

    // ANCHOR - Fetch Agencies
    const agenciesManagerList = ref([])
    const agenciesManagerListDefault = ref([])

    const fetchAgenciesManagerByFilter = async (search = '') => {
      agenciesManagerList.value = []
      loading.value = true
      try {
        const { data } = await apiAgencies.fetchAgencies({ isActive: true, searchText: search })
        if (data && !search) {
          agenciesManagerListDefault.value = data.items
        }
        agenciesManagerList.value = data.items
      } catch (error) {
        console.error(error)
      } finally {
        loading.value = false
      }
    }

    const openAgenciesManager = () => {
      if (agenciesManagerListDefault.value.length === 0) {
        fetchAgenciesManagerByFilter()
      } else {
        agenciesManagerList.value = agenciesManagerListDefault.value
      }
    }

    const searchAgenciesManager = debounce(search => {
      if (search) fetchAgenciesManagerByFilter(search)
    }, 300)

    // Check limit xem duoc xuat ko
    const isDisabledByCheckLimit = ref(true)
    async function checkCreditForPayment(bookingData) {
      const payload = {
        id: bookingData?.createdBy?.agency?.id,
        amount: bookingData.totalPrice || 0,
      }
      isLoading.value = true
      try {
        const res = await apiAgencies.checkCredit(payload.id, { amount: payload.amount })
        resCheckCredit.value = {
          ...res.data,
          isWarning: false, // ẩn cảnh báo warningLimit
          isWarningLimitPercent: false, // ẩn cảnh báo warningLimit
        }
        // isDisabledByCheckLimit.value = res.data.isOverLimit || res.data.isOverLimitParent // dùng isPaymentPermission của dũng
        isDisabledByCheckLimit.value = !res.data.isPaymentPermission

        // F1 xuất over cho F2, F3
        if (isRoleF1.value) {
          // isDisabledByCheckLimit.value = false
          if (res.data.isOverLimit || res.data.isOverLimitParent) isOverPayment.value = true
          // F2 xuất over cho F3
        } else if (res.data.isOverLimit && !res.data.isOverLimitParent && bookingData?.createdBy?.agency?.parentAgency?.id === getAgencyData.value?.id) {
          // isDisabledByCheckLimit.value = false
          isOverPayment.value = true
        }
      } catch (err) {
        console.error(err)
      } finally {
        isLoading.value = false
      }
    }

    // ANCHOR handle open modal
    async function handleOpenModal() {
      agencyManager.value = props.bookingData.createdBy.agency

      if (agencyManager.value !== null) {
        openAgenciesManager()
      }
      if (props.bookingData?.createdBy?.agency?.id !== 1000000) {
        checkCreditForPayment(props.bookingData)
      } else {
        resCheckCredit.value = 0
        // isDisabledPayment.value = false
        isDisabledByCheckLimit.value = false
      }
    }

    const getMeEnablePaymentPassword = computed(() => store.getters['userStore/getMeEnablePaymentPassword'])
    async function paymentHandle(bookingData = {}, thisParam) {
      // Mat khau thanh toan
      let paymentPassword
      if (getMeEnablePaymentPassword.value) {
        paymentPassword = await Vue.swal({
          title: thisParam.$t('myAccount.paymentPassword.title'),
          html: '<div id="i-am-payment-password"></div>',
          didOpen: () => {
            new Vue({
              render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'off' } }),
              i18n,
            }).$mount('#i-am-payment-password')
          },
          focusConfirm: false,
          preConfirm: () => new Promise(resolve => {
            const pwd = document.getElementById('swal-input-payment-password-1').value
            if (!validatorPaymentPassword(pwd)) {
              Vue.swal.showValidationMessage(thisParam.$t('myAccount.paymentPassword.errorInput'))
            } else { resolve(pwd) }
          }),
          showCancelButton: true,
          confirmButtonText: thisParam.$t('confirmation'),
          cancelButtonText: thisParam.$t('cancel'),
          buttonsStyling: true,
          customClass: {
            confirmButton: 'btn btn-gradient',
            cancelButton: 'btn btn-danger mr-1',
          },
          allowOutsideClick: false,
          reverseButtons: true,
        })
        if (paymentPassword.isDenied || paymentPassword.isDismissed) {
          return
        }
      }

      thisParam.$bvModal.show('modal-api-loading')

      const payload = {
        bookingCode: bookingData.bookingCode,
        paymentId: bookingData.paymentId,
        amount: bookingData.totalPrice,
        paymentPassword: paymentPassword ? paymentPassword.value : undefined,
      }

      paymentTrains(payload)
        .then(() => {
          thisParam.$bvModal.hide('modal-payment-train-ticket')
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => thisParam.$bvModal.hide('modal-api-loading'))
    }

    function confirmPayment(bookingData = {}) {
      if (bookingData.bookingStatus === 'CANCELLED' && ['QH', 'VJ', 'VU'].includes(bookingData.source)) {
        toastError('reservation[\'Booking code has been cancelled. Unable to pay!\']')
        return
      }
      if (isDisabledByCheckLimit.value) {
        toastError('reservation[\'Please check your credit limit for payment\']')
        return
      }

      if (isOverPayment.value) {
        this.$bvModal
          .msgBoxConfirm(this.$t('reservation.f1PaymentForF2'), {
            title: this.$t('confirmation'),
            size: 'sm',
            okVariant: 'success',
            okTitle: this.$t('confirmation'),
            cancelTitle: this.$t('modal.no'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              paymentHandle(bookingData, this)
            }
          })
      } else {
        paymentHandle(bookingData, this)
      }
    }

    const totalPriceFare = computed(() => bookingData.value.totalPrice || 0)
    const totalToBePay = computed(() => bookingData.value.totalPrice || 0)

    const showLimitCredit = ref(false)

    function handleChangeShowLimitCredit() {
      showLimitCredit.value = !showLimitCredit.value
    }

    // Ẩn giá vé khi vé VJ đã thanh toán (case thanh toán phí treo)
    const isShowPriceTicket = computed(() => {
      if (bookingData.value?.source === 'VJ' && bookingData.value?.status.includes('PAID')) {
        return false
      }
      return true
    })

    // Hiển thị code(tên) của đai lý khi hết hạn mức
    const agencyParentString = computed(() => {
      const code = bookingData.value?.createdBy?.agency?.parentAgency?.agencyCode || ''
      const name = ` (${bookingData.value?.createdBy?.agency?.parentAgency?.agencyName})` || ''
      return `${code}${name}`
    })

    const agencyString = computed(() => {
      const code = bookingData.value?.createdBy?.agency?.agencyCode || ''
      const name = ` (${bookingData.value?.createdBy?.agency?.agencyName})` || ''
      return `${code}${name}`
    })
    const lockedList = ref([])
    function handleShowLockedList() {
      lockedList.value = resCheckCredit.value?.lockedList || []
      this.$bvModal.show('modal-locked-list')
    }

    return {
      isMamaAgency,
      agencyManager,
      // isDisabledPayment,
      isLoading,
      formatCurrency,
      convertISODateTime,
      confirmPayment,
      handleOpenModal,
      loading,
      resCheckCredit,

      agenciesManagerList,
      openAgenciesManager,
      searchAgenciesManager,
      isEmpty,
      totalPriceFare,
      totalToBePay,

      isRoleF1,
      showLimitCredit,
      handleChangeShowLimitCredit,

      tablePriceQuotesColumn,
      isShowPriceTicket,
      isOverPayment,
      isDisabledByCheckLimit,

      agencyString,
      agencyParentString,
      removeAccentsUpperCaseFormatter,
      specifiedPassengers,
      handleShowLockedList,
      lockedList,
    }
  },
  // !SECTION
}
</script>

<style lang="scss" scoped>
.agency_select ::v-deep {
  .vs__dropdown-toggle  {
    padding-bottom: 0 !important;
    padding-top: 3px !important;
    .vs__actions {
      display: none !important;
    }
  }
}
</style>
