var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppCollapse', {
    staticClass: "mb-50",
    attrs: {
      "type": "border-info-2 card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-info-2",
    attrs: {
      "is-visible": "",
      "title": "",
      "class-header": "card-header bg-blue-gradient p-0 m-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-between w-100 pr-50"
        }, [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('IAmIcon', {
          staticClass: "icon-card text-airline",
          attrs: {
            "icon": "passengers",
            "size": "24"
          }
        }), _c('h6', {
          staticClass: "text-airline fw-700 font-medium-3 m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.bookingInfo.title')) + " ")])], 1), _c('div', {
          staticClass: "d-flex-center mr-2"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.window",
            modifiers: {
              "hover": true,
              "window": true
            }
          }],
          staticClass: "p-50 rounded-circle mx-25",
          attrs: {
            "variant": "flat-primary",
            "title": "".concat(_vm.$t("train.detail.bookingInfo.".concat(_vm.showPriceTicket ? 'show' : 'hide', "PriceTicket")))
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
              _vm.showPriceTicket = !_vm.showPriceTicket;
            }
          }
        }, [_vm.showPriceTicket ? _c('img', {
          attrs: {
            "src": require("@icons/money.svg")
          }
        }) : _c('img', {
          attrs: {
            "src": require("@icons/not_money.svg")
          }
        })])], 1)])];
      },
      proxy: true
    }])
  }, [_c('div', [_c('TrainTicketDetailBookingInfoTable', {
    attrs: {
      "bookingData": _vm.bookingData,
      "showPriceTicket": _vm.showPriceTicket
    }
  }), _c('div', {
    staticClass: "d-flex-between w-100"
  }, [_vm.showPriceTicket ? _c('div', {
    staticClass: "px-1 py-50 rounded d-flex-center fit-content gap-2",
    style: "border: 1px solid ".concat(_vm.showProfit ? '#E6E6E6' : 'transparent', "; height: 64px")
  }, [_c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.window",
      modifiers: {
        "hover": true,
        "window": true
      }
    }],
    staticClass: "p-25 rounded-circle",
    attrs: {
      "variant": "primary",
      "title": "".concat(_vm.$t("train.detail.bookingInfo.".concat(_vm.showProfit ? 'show' : 'hide', "Profit")))
    },
    on: {
      "click": function click($event) {
        _vm.showProfit = !_vm.showProfit;
      }
    }
  }, [_vm.showProfit ? _c('feather-icon', {
    attrs: {
      "icon": "EyeIcon",
      "size": "16"
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "EyeOffIcon",
      "size": "16"
    }
  })], 1), _vm.showProfit ? _c('div', {
    staticClass: "d-flex-center gap-2"
  }, [_c('span', {
    staticClass: "fw-600"
  }, [_vm._v(_vm._s(_vm.$t('reservation.benefit')) + ": ")]), _c('span', {
    class: "text-".concat(_vm.profit > 0 ? 'success' : 'danger', " text-20px fw-700")
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.profit)) + " ")])]) : _vm._e()], 1) : _c('div'), _c('div', {
    staticClass: "d-flex-center bg-light-primary px-3 py-1 gap-2 rounded fit-content"
  }, [_c('div', {
    staticClass: "fw-600 text-18px"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.bookingInfo.columns.totalPrice')) + ": ")]), _c('div', {
    staticClass: "fw-700 text-24px"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.showPriceTicket ? _vm.bookingData.totalPriceTicket : _vm.bookingData.totalPricePaid)) + " ")])])])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }