var render = function () {
  var _vm$resCheckCredit, _vm$resCheckCredit2, _vm$resCheckCredit3, _vm$resCheckCredit4, _vm$resCheckCredit5, _vm$resCheckCredit6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-payment-train-ticket",
      "title": _vm.$t('reservation.payment'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "ok-variant": "primary",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "lg",
      "no-enforce-focus": ""
    },
    on: {
      "show": _vm.handleOpenModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-button', {
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient border-right-0",
          attrs: {
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return _vm.confirmPayment(_vm.bookingData);
            }
          }
        }, [_c('div', {
          staticClass: "d-flex-center px-25"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.payment')) + " ")])])];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading || _vm.isLoading,
      "spinner-variant": "primary"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-between"
        }, [_c('span', {
          staticClass: "text-dark font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.$t('employee.agencies')))])])];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    staticClass: "agency_select",
    attrs: {
      "options": _vm.agenciesManagerList,
      "label": "agencyName",
      "searchable": "",
      "clearable": false,
      "filterable": false,
      "input-id": "agency",
      "disabled": true,
      "placeholder": _vm.$t('placeholderSelect')
    },
    on: {
      "open": _vm.openAgenciesManager,
      "search": _vm.searchAgenciesManager
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-between"
        }, [_c('span', {
          staticClass: "font-weight-bolder d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(data.agencyName) + " ")])])];
      }
    }, {
      key: "option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-between"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(data.agencyName) + " ")]), _c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(data.agencyCode) + " ")])])];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref2) {
        var loading = _ref2.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('flight.noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.agencyManager,
      callback: function callback($$v) {
        _vm.agencyManager = $$v;
      },
      expression: "agencyManager"
    }
  })], 1)], 1), _vm.agencyManager ? _c('b-col', {
    staticClass: "text-dark",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.agencyName')) + ": ")])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "8"
    }
  }, [_c('span', {
    staticClass: "text-airline font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.agencyManager.agencyName) + " ("), _c('span', {
    staticClass: "text-uppercase"
  }, [_vm._v(_vm._s(_vm.agencyManager.agencyCode))]), _vm._v(") ")])])], 1), !_vm.isMamaAgency ? _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.balanceLimit')) + " "), (_vm$resCheckCredit = _vm.resCheckCredit) !== null && _vm$resCheckCredit !== void 0 && _vm$resCheckCredit.lockBalance ? _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" + " + _vm._s(_vm.$t('lockBalance')))]) : _vm._e(), _vm.showLimitCredit ? _c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(" / " + _vm._s(_vm.$t('reservation.limitCredit')))]) : _vm._e(), _vm._v(" : ")])]), _c('b-col', {
    staticClass: "text-right d-flex-center justify-content-end",
    attrs: {
      "cols": "6"
    }
  }, [_c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-info",
      modifiers: {
        "hover": true,
        "v-info": true
      }
    }],
    staticClass: "p-50 rounded-circle",
    attrs: {
      "title": "".concat(_vm.$t(_vm.showLimitCredit ? 'hide' : 'show'), " ").concat(_vm.$t('reservation.limitCredit')),
      "variant": "flat-info"
    },
    on: {
      "click": _vm.handleChangeShowLimitCredit
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": _vm.showLimitCredit ? 'EyeIcon' : 'EyeOffIcon'
    }
  })], 1), _vm.resCheckCredit ? _c('span', [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.resCheckCredit.balance)) + " "), (_vm$resCheckCredit2 = _vm.resCheckCredit) !== null && _vm$resCheckCredit2 !== void 0 && _vm$resCheckCredit2.lockBalance ? _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-danger",
      modifiers: {
        "hover": true,
        "v-danger": true
      }
    }],
    staticClass: "text-danger",
    attrs: {
      "title": _vm.$t('lockBalance')
    }
  }, [_vm._v(" + " + _vm._s(_vm.formatCurrency((_vm$resCheckCredit3 = _vm.resCheckCredit) === null || _vm$resCheckCredit3 === void 0 ? void 0 : _vm$resCheckCredit3.lockBalance)))]) : _vm._e(), _vm.showLimitCredit ? _c('span', [_vm._v(" / " + _vm._s(_vm.formatCurrency(_vm.resCheckCredit.limitCredit)))]) : _vm._e()]) : _c('span', [_vm._v(" 0 ")]), (_vm$resCheckCredit4 = _vm.resCheckCredit) !== null && _vm$resCheckCredit4 !== void 0 && _vm$resCheckCredit4.lockBalance && (_vm$resCheckCredit5 = _vm.resCheckCredit) !== null && _vm$resCheckCredit5 !== void 0 && _vm$resCheckCredit5.lockedList && ((_vm$resCheckCredit6 = _vm.resCheckCredit) === null || _vm$resCheckCredit6 === void 0 ? void 0 : _vm$resCheckCredit6.lockedList.length) > 0 ? _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-dark",
      modifiers: {
        "hover": true,
        "v-dark": true
      }
    }],
    staticClass: "p-25 rounded-circle ml-25",
    attrs: {
      "variant": "flat-dark",
      "title": _vm.$t('reservation.lockedList.title')
    },
    on: {
      "click": _vm.handleShowLockedList
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "dotsOutline",
      "size": "18"
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.isShowPriceTicket ? _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.ticketPrice')) + ": ")])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [!_vm.totalToBePay && _vm.totalToBePay !== 0 ? _c('b-spinner', {
    staticStyle: {
      "width": "20px",
      "height": "20px"
    },
    attrs: {
      "variant": "info"
    }
  }) : _c('span', [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalToBePay)) + " ")])], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "mb-50 font-weight-bolder"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.totalAmountNeedToPay')) + ": ")])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm.totalPriceFare || _vm.totalPriceFare === 0 ? _c('span', [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPriceFare)) + " ")]) : _c('b-spinner', {
    staticStyle: {
      "width": "20px",
      "height": "20px"
    },
    attrs: {
      "variant": "info"
    }
  })], 1)], 1), !_vm.isMamaAgency && _vm.resCheckCredit ? _c('div', [_c('hr', {
    staticClass: "font-weight-bolder"
  }), _c('b-row', {
    staticClass: "font-weight-bolder mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.balanceAfterPayment')) + ": ")])]), _c('b-col', {
    staticClass: "text-right text-16px",
    class: _vm.resCheckCredit ? "text-".concat(_vm.resCheckCredit.isOverLimit || _vm.resCheckCredit.isOverLimitParent || _vm.resCheckCredit.isWarningLimitPercent ? 'danger' : _vm.resCheckCredit.isWarning || _vm.resCheckCredit.isWarningLimitPercent ? 'warning' : 'success') : '',
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.formatCurrency(Number(_vm.resCheckCredit.balance) + Number(_vm.totalPriceFare))) + " ")])])], 1)], 1) : _vm._e(), _vm.resCheckCredit && (_vm.resCheckCredit.isOverLimit || _vm.resCheckCredit.isOverLimitParent || _vm.resCheckCredit.isWarningLimitPercent || _vm.resCheckCredit.isWarning) ? _c('b-row', {
    staticClass: "mb-50"
  }, [_vm.resCheckCredit.isOverLimit || _vm.resCheckCredit.isWarningLimitPercent ? _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "py-1 text-title my-25",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.resCheckCredit.isOverLimit ? _vm.$t('reservation.paymentOverLimit', {
        agencyCode: _vm.agencyString
      }) : _vm.resCheckCredit.isWarningLimitPercent ? _vm.$t('reservation.isWarningLimitPercent', {
        agencyCode: _vm.agencyString,
        percent: _vm.resCheckCredit.warningLimitPercent
      }) : _vm.$t('reservation.paymentWarning', {
        agencyCode: _vm.agencyString
      }))
    }
  })])], 1) : _vm.resCheckCredit.isWarning ? _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "py-1 text-title my-25",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('reservation.paymentWarning', {
        agencyCode: _vm.agencyString
      }))
    }
  })])], 1) : _vm._e(), _vm.resCheckCredit.isOverLimitParent ? _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "py-1 text-title my-25",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('reservation.isOverLimitParent', {
        agencyCode: _vm.agencyParentString
      }))
    }
  })])], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }