var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppCollapse', {
    staticClass: "mb-50",
    attrs: {
      "type": "border-info-2 card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-info-2",
    attrs: {
      "is-visible": "",
      "title": "",
      "class-header": "card-header bg-blue-gradient p-0 m-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-between w-100 pr-50"
        }, [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('IAmIcon', {
          staticClass: "icon-card text-airline",
          attrs: {
            "icon": "trainIcon",
            "size": "24"
          }
        }), _c('h6', {
          staticClass: "text-airline fw-700 font-medium-3 m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.tripInfo.title')) + " ")])], 1)])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex-center align-items-stretch flex-column gap-2"
  }, _vm._l(_vm.bookingData.listTrip, function (trip, tripIndex) {
    var _trip$tripInfos;
    return _c('BCard', {
      key: tripIndex,
      staticClass: "border-info",
      attrs: {
        "header-bg-variant": "light",
        "border-variant": "light",
        "body-class": "d-flex-center"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('span', {
            staticClass: "fw-600 font-italic"
          }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.tripInfo.trip')) + " #" + _vm._s(tripIndex + 1) + " ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "fit-content"
    }, [_c('div', {
      staticClass: "d-flex-center gap-1 fw-700"
    }, [_c('div', {
      staticClass: "d-flex-center flex-column gap-1"
    }, [_c('div', {
      staticClass: "text-22px"
    }, [_vm._v(" " + _vm._s(trip === null || trip === void 0 ? void 0 : trip.departureName) + " "), _c('span', {
      staticClass: "text-primary"
    }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : trip.departure))])])]), _c('div', {
      staticClass: "d-flex flex-column align-items-center mx-1 my-auto"
    }, [_c('div', {
      staticClass: "text-nowrap d-flex-center align-items-end gap-0"
    }, [_c('svg', {
      staticClass: "mb-25",
      attrs: {
        "width": "105",
        "height": "2",
        "viewBox": "0 0 105 2",
        "fill": "none",
        "xmlns": "http://www.w3.org/2000/svg"
      }
    }, [_c('path', {
      attrs: {
        "d": "M0 1H105",
        "stroke": "black"
      }
    })]), _c('IAmIcon', {
      attrs: {
        "icon": "trainGo",
        "size": "20"
      }
    })], 1)]), _c('div', {
      staticClass: "d-flex-center flex-column gap-1"
    }, [_c('div', {
      staticClass: "text-22px"
    }, [_vm._v(" " + _vm._s(trip === null || trip === void 0 ? void 0 : trip.arrivalName) + " "), _c('span', {
      staticClass: "text-primary"
    }, [_vm._v(_vm._s(trip === null || trip === void 0 ? void 0 : trip.arrival))])])])]), _c('div', _vm._l(_vm.uniqWith((_trip$tripInfos = trip === null || trip === void 0 ? void 0 : trip.tripInfos) !== null && _trip$tripInfos !== void 0 ? _trip$tripInfos : [], _vm.compareTripInfo), function (tripInfo, tripInfoIndex) {
      var _vm$convertISODateTim, _vm$convertISODateTim2;
      return _c('div', {
        key: tripInfoIndex,
        staticClass: "d-flex-center gap-2 text-18px"
      }, [_c('em', {
        staticClass: "mb-0"
      }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.tripInfo.train')) + " "), _c('strong', [_vm._v(_vm._s(tripInfo === null || tripInfo === void 0 ? void 0 : tripInfo.trainCode))]), _vm._v(": ")]), _c('div', {
        staticClass: "flex-1 d-flex-center gap-1"
      }, [_c('strong', [_vm._v(_vm._s(tripInfo === null || tripInfo === void 0 ? void 0 : tripInfo.departureHour))]), _c('div', [_vm._v(_vm._s((_vm$convertISODateTim = _vm.convertISODateTime(tripInfo === null || tripInfo === void 0 ? void 0 : tripInfo.departureTime)) === null || _vm$convertISODateTim === void 0 ? void 0 : _vm$convertISODateTim.date))])]), _c('IAmIcon', {
        attrs: {
          "icon": "arrowRight",
          "size": "20"
        }
      }), _c('div', {
        staticClass: "flex-1 d-flex-center gap-1"
      }, [_c('strong', [_vm._v(_vm._s(tripInfo === null || tripInfo === void 0 ? void 0 : tripInfo.arrivalHour))]), _c('div', [_vm._v(_vm._s((_vm$convertISODateTim2 = _vm.convertISODateTime(tripInfo === null || tripInfo === void 0 ? void 0 : tripInfo.arrivalTime)) === null || _vm$convertISODateTim2 === void 0 ? void 0 : _vm$convertISODateTim2.date))])])], 1);
    }), 0)])]);
  }), 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }