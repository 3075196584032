var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppCollapse', {
    staticClass: "mb-50",
    attrs: {
      "type": "border-info-2 card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-info-2",
    attrs: {
      "title": "",
      "class-header": "card-header bg-blue-gradient p-0 m-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-between w-100 pr-50"
        }, [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('IAmIcon', {
          staticClass: "icon-card text-airline",
          attrs: {
            "icon": "historyAction",
            "size": "24"
          }
        }), _c('h6', {
          staticClass: "text-airline fw-700 font-medium-3 m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.auditLog.title')) + " ")])], 1)])];
      },
      proxy: true
    }])
  }, [_c('div', [_c('b-table', {
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "65vh"
    },
    attrs: {
      "items": _vm.auditLogs,
      "fields": _vm.tableColumns,
      "responsive": "",
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "small": "",
      "bordered": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn() {
          return [_c('span', {
            key: column.key,
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("train.detail.auditLog.columns.".concat(column.label))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, {
      key: "table-colgroup",
      fn: function fn(_ref) {
        var fields = _ref.fields;
        return _vm._l(fields, function (field) {
          return _c('col', {
            key: field.key,
            style: field.style
          });
        });
      }
    }, {
      key: "cell(orderNo)",
      fn: function fn(_ref2) {
        var index = _ref2.index;
        return [_vm._v(" " + _vm._s(_vm.auditLogs.length - index) + " ")];
      }
    }, {
      key: "cell(date)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.timestamp).dateTimeSecond) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('b-badge', {
          staticClass: "badge-glow",
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" " + _vm._s(_vm.getHistoryActionBookingFunction(item.action) || _vm.$t("reservation.historyActions.".concat(item.action))) + " ")])];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "text-center font-weight-bold"
        }, [Number(item.newValue) && !isNaN(Number(item.newValue)) ? _c('div', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(Number(item.newValue))) + " ")]) : _c('div', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(item.newValue ? JSON.parse(item.newValue) ? _vm.formatCurrency(JSON.parse(item.newValue).amount) : item.newValue : '') + " ")])])];
      }
    }, {
      key: "cell(executor)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm.getLocaleFullName(item.modifiedBy)) + " ")];
      }
    }], null, true)
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }