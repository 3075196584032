<template lang="">
  <AppCollapse
    type="border-info-2 card"
    class="mb-50"
  >
    <AppCollapseItem
      is-visible
      title
      class-header="card-header bg-blue-gradient p-0 m-50"
      class="border-info-2"
    >
      <template #header>
        <div class="d-flex-between w-100 pr-50">
          <div class="d-flex-center justify-content-start gap-1">
            <IAmIcon
              icon="passengers"
              class="icon-card text-airline"
              size="24"
            />
            <h6 class="text-airline fw-700 font-medium-3 m-0">
              {{ $t('train.detail.bookingInfo.title') }}
            </h6>
          </div>

          <div class="d-flex-center mr-2">
            <b-button
              v-b-tooltip.hover.window
              variant="flat-primary"
              class="p-50 rounded-circle mx-25"
              :title="`${$t(`train.detail.bookingInfo.${showPriceTicket ? 'show' : 'hide'}PriceTicket`)}`"
              @click.prevent.stop="showPriceTicket = !showPriceTicket"
            >
              <img
                v-if="showPriceTicket"
                src="@icons/money.svg"
              >
              <img
                v-else
                src="@icons/not_money.svg"
              >
            </b-button>
          </div>
        </div>
      </template>
      <div>
        <TrainTicketDetailBookingInfoTable
          :bookingData="bookingData"
          :showPriceTicket="showPriceTicket"
        />

        <div class="d-flex-between w-100">
          <div
            v-if="showPriceTicket"
            class="px-1 py-50 rounded d-flex-center fit-content gap-2"
            :style="`border: 1px solid ${showProfit ? '#E6E6E6' : 'transparent'}; height: 64px`"
          >
            <BButton
              v-b-tooltip.hover.window
              variant="primary"
              :title="`${$t(`train.detail.bookingInfo.${showProfit ? 'show' : 'hide'}Profit`)}`"
              class="p-25 rounded-circle"
              @click="showProfit = !showProfit"
            >
              <feather-icon
                v-if="showProfit"
                icon="EyeIcon"
                size="16"
              />
              <feather-icon
                v-else
                icon="EyeOffIcon"
                size="16"
              />
            </BButton>
            <div
              v-if="showProfit"
              class="d-flex-center gap-2"
            >
              <span class="fw-600">{{ $t('reservation.benefit') }}: </span>
              <span :class="`text-${profit > 0 ? 'success' : 'danger'} text-20px fw-700`">{{ formatCurrency(profit) }} </span>
            </div>
          </div>
          <div v-else />
          <div class="d-flex-center bg-light-primary px-3 py-1 gap-2 rounded fit-content">
            <div class="fw-600 text-18px">
              {{ $t('train.detail.bookingInfo.columns.totalPrice') }}:
            </div>
            <div class="fw-700 text-24px">
              {{ formatCurrency(showPriceTicket ? bookingData.totalPriceTicket : bookingData.totalPricePaid) }}
            </div>
          </div>
        </div>
      </div>
    </AppCollapseItem>
  </AppCollapse>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'

import { formatCurrency } from '@/@core/utils/filter'

export default {
  components: {
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    BButton,
    TrainTicketDetailBookingInfoTable: () => import('./TrainTicketDetailBookingInfoTable.vue'),
  },
  props: {
    bookingData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const showPriceTicket = ref(true)
    const showProfit = ref(false)
    const profit = computed(() => props.bookingData?.totalPriceTicket - props.bookingData?.totalPricePaid)

    return {
      showPriceTicket,
      formatCurrency,
      showProfit,
      profit,
    }
  },
}
</script>
<style lang="">

</style>
