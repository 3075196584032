var render = function () {
  var _vm$bookingData2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "border-info-2 shadow-card mb-50",
    attrs: {
      "body-class": "py-75 pb-md-1 px-75 px-md-2"
    }
  }, [_c('div', {
    staticClass: "d-flex-center w-100 mb-50"
  }, [_c('div', {
    staticClass: "d-flex align-items-center gap-2 flex-1"
  }, [_c('div', {
    staticClass: "fw-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.bookingCode')) + ": ")]), _c('div', {
    staticClass: "d-flex-center border-airline px-1 py-75 bg-light-info rounded-8px my-25",
    staticStyle: {
      "width": "fit-content"
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder d-flex-center justify-content-start"
  }, [_c('b', {
    staticClass: "text-airline font-large-1 text-nowrap",
    class: "".concat(_vm.isMobileView ? 'font-medium-3' : 'font-large-1')
  }, [_vm._v(" " + _vm._s(_vm.bookingData.bookingCode) + " ")]), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.window.top",
      value: _vm.$t('copy'),
      expression: "$t('copy')",
      modifiers: {
        "hover": true,
        "window": true,
        "top": true
      }
    }],
    staticClass: "p-50 rounded-circle ml-25",
    attrs: {
      "variant": "flat-primary",
      "disabled": _vm.disabledCopy
    },
    on: {
      "click": function click($event) {
        var _vm$bookingData;
        return _vm.copyText((_vm$bookingData = _vm.bookingData) === null || _vm$bookingData === void 0 ? void 0 : _vm$bookingData.bookingCode);
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "copy",
      "size": "18",
      "color": "#2FA4E5"
    }
  })], 1)], 1)])]), _c('div', {
    staticClass: "d-flex gap-2 flex-1"
  }, [_c('div', {
    staticClass: "fw-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.status.title')) + ": ")]), _c('b-badge', {
    class: "badge-glow round text-uppercase ".concat(_vm.isMobileView ? 'font-medium-2' : 'font-medium-3', " fw-800 "),
    attrs: {
      "variant": "light-".concat(_vm.resolveTrainTicketStatusVariant(_vm.bookingData.status))
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("train.status.".concat((_vm$bookingData2 = _vm.bookingData) === null || _vm$bookingData2 === void 0 ? void 0 : _vm$bookingData2.status))) + " ")])], 1)]), _c('div', {
    staticClass: "d-flex justify-content-center flex-column gap-2"
  }, [_vm.bookingData.timeHold ? _c('div', {
    staticClass: "fw-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.timeHold')) + ": "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(_vm.bookingData.timeHold).dateTime) + " ")])]) : _vm._e(), _vm.bookingData.createdAt ? _c('div', {
    staticClass: "fw-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.createdAt')) + ": " + _vm._s(_vm.convertISODateTime(_vm.bookingData.createdAt).dateTime) + " ")]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }