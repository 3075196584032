<template lang="">
  <AppCollapse
    type="border-info-2 card"
    class="mb-50"
  >
    <AppCollapseItem
      is-visible
      title
      class-header="card-header bg-blue-gradient p-0 m-50"
      class="border-info-2"
    >
      <template #header>
        <div class="d-flex-between w-100 pr-50">
          <div class="d-flex-center justify-content-start gap-1">
            <IAmIcon
              icon="trainIcon"
              class="icon-card text-airline"
              size="24"
            />
            <h6 class="text-airline fw-700 font-medium-3 m-0">
              {{ $t('train.detail.tripInfo.title') }}
            </h6>
          </div>
        </div>
      </template>
      <div class="d-flex-center align-items-stretch flex-column gap-2">
        <BCard
          v-for="(trip, tripIndex) of bookingData.listTrip"
          :key="tripIndex"
          header-bg-variant="light"
          border-variant="light"
          class="border-info"
          body-class="d-flex-center"
        >
          <template #header>
            <span class="fw-600 font-italic">
              {{ $t('train.detail.tripInfo.trip') }} #{{ tripIndex + 1 }}
            </span>
          </template>
          <div class="fit-content">
            <div class="d-flex-center gap-1 fw-700">
              <div class="d-flex-center flex-column gap-1">
                <div class="text-22px">
                  {{ trip?.departureName }}
                  <span class="text-primary">{{ trip?.departure }}</span>
                </div>
              </div>
              <div class="d-flex flex-column align-items-center mx-1 my-auto">
                <div class="text-nowrap d-flex-center align-items-end gap-0">
                  <svg
                    class="mb-25"
                    width="105"
                    height="2"
                    viewBox="0 0 105 2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 1H105"
                      stroke="black"
                    />
                  </svg>
                  <IAmIcon
                    icon="trainGo"
                    size="20"
                  />
                </div>
              </div>
              <div class="d-flex-center flex-column gap-1">
                <div class="text-22px">
                  {{ trip?.arrivalName }}
                  <span class="text-primary">{{ trip?.arrival }}</span>
                </div>
              </div>
            </div>
            <div>
              <div
                v-for="(tripInfo, tripInfoIndex) of uniqWith(trip?.tripInfos ?? [], compareTripInfo)"
                :key="tripInfoIndex"
                class="d-flex-center gap-2 text-18px"
              >
                <em class="mb-0">
                  {{ $t('train.detail.tripInfo.train') }} <strong>{{ tripInfo?.trainCode }}</strong>:
                </em>
                <div class="flex-1 d-flex-center gap-1">
                  <strong>{{ tripInfo?.departureHour }}</strong>
                  <div>{{ convertISODateTime(tripInfo?.departureTime)?.date }}</div>
                </div>
                <IAmIcon
                  icon="arrowRight"
                  size="20"
                />
                <div class="flex-1 d-flex-center gap-1">
                  <strong>{{ tripInfo?.arrivalHour }}</strong>
                  <div>{{ convertISODateTime(tripInfo?.arrivalTime)?.date }}</div>
                </div>
              </div>
            </div>
          </div>
        </BCard>
      </div>
    </AppCollapseItem>
  </AppCollapse>
</template>
<script>
import { BCard } from 'bootstrap-vue'
import uniqWith from 'lodash/uniqWith'

import { convertISODateTime } from '@/@core/utils/filter'

export default {
  components: {
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    BCard,
  },
  props: {
    bookingData: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const compareTripInfo = (tripInfo1, tripInfo2) => tripInfo1?.trainCode === tripInfo2?.trainCode
        && tripInfo1?.departureHour === tripInfo2?.departureHour
        && tripInfo1?.departureTime === tripInfo2?.departureTime
        && tripInfo1?.arrivalHour === tripInfo2?.arrivalHour
        && tripInfo1?.arrivalTime === tripInfo2?.arrivalTime
    return {
      convertISODateTime,
      uniqWith,
      compareTripInfo,
    }
  },
}
</script>
<style lang="">

</style>
