var render = function () {
  var _vm$bookingData$listT, _vm$bookingData$audit, _vm$bookingData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_vm.bookingData ? _c('div', {
    staticClass: "d-flex flex-column gap-2 p-1"
  }, [_c('TrainTicketDetailHeader', {
    attrs: {
      "bookingData": _vm.bookingData
    }
  }), _c('TrainTicketDetailContactInfo', {
    attrs: {
      "bookingData": _vm.bookingData
    }
  }), (_vm$bookingData$listT = _vm.bookingData.listTrip) !== null && _vm$bookingData$listT !== void 0 && _vm$bookingData$listT.length ? _c('TrainTicketDetailTripInfo', {
    attrs: {
      "bookingData": _vm.bookingData
    }
  }) : _vm._e(), _c('TrainTicketDetailBookingInfo', {
    attrs: {
      "bookingData": _vm.bookingData
    }
  }), _c('TrainTicketDetailAuditLogs', {
    attrs: {
      "auditLogs": (_vm$bookingData$audit = (_vm$bookingData = _vm.bookingData) === null || _vm$bookingData === void 0 ? void 0 : _vm$bookingData.auditLogs) !== null && _vm$bookingData$audit !== void 0 ? _vm$bookingData$audit : []
    }
  })], 1) : _vm._e(), _c('TrainTicketDetailFooter', {
    attrs: {
      "bookingData": _vm.bookingData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }