var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppCollapse', {
    staticClass: "mb-50",
    attrs: {
      "type": "border-info-2 card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-info-2",
    attrs: {
      "is-visible": "",
      "title": "",
      "class-header": "card-header bg-blue-gradient p-0 m-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-between w-100 pr-50"
        }, [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('IAmIcon', {
          staticClass: "icon-card text-airline",
          attrs: {
            "icon": "phone",
            "size": "24"
          }
        }), _c('h6', {
          staticClass: "text-airline fw-700 font-medium-3 m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.contact.title')) + " ")])], 1)])];
      },
      proxy: true
    }])
  }, [_c('div', [_c('BRow', {}, [_c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.contact.fullName')) + ": "), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.bookingData.bookingPerson.fullName) + " ")])]), _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.contact.phoneNumber')) + ": "), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.bookingData.bookingPerson.phoneNumber))])]), _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.contact.identifyNumber')) + ": "), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.bookingData.bookingPerson.identifyNumber))])]), _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.contact.email')) + ": "), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.bookingData.bookingPerson.email))])])], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }