<template lang="">
  <b-card
    body-class="py-75 pb-md-1 px-75 px-md-2"
    class="border-info-2 shadow-card mb-50"
  >
    <!-- ANCHOR HEADER: bookingCode -->
    <div class="d-flex-center w-100 mb-50">
      <div class="d-flex align-items-center gap-2 flex-1">
        <div class="fw-600">
          {{ $t('train.bookingCode') }}:
        </div>
        <div
          class="d-flex-center border-airline px-1 py-75 bg-light-info rounded-8px my-25"
          style="width: fit-content;"
        >
          <span class="font-weight-bolder d-flex-center justify-content-start">
            <b
              class="text-airline font-large-1 text-nowrap"
              :class="`${isMobileView ? 'font-medium-3' : 'font-large-1'}`"
            >
              {{ bookingData.bookingCode }}
            </b>
            <BButton
              v-b-tooltip.hover.window.top="$t('copy')"
              variant="flat-primary"
              class="p-50 rounded-circle ml-25"
              :disabled="disabledCopy"
              @click="copyText(bookingData?.bookingCode)"
            >
              <IAmIcon
                icon="copy"
                size="18"
                color="#2FA4E5"
              />
            </BButton>
          </span>
        </div>
      </div>

      <div class="d-flex gap-2 flex-1">
        <div class="fw-600">
          {{ $t('train.status.title') }}:
        </div>
        <b-badge
          :variant="`light-${resolveTrainTicketStatusVariant(bookingData.status)}`"
          :class="`badge-glow round text-uppercase ${isMobileView ? 'font-medium-2' : 'font-medium-3'} fw-800 `"
        >
          {{ $t(`train.status.${bookingData?.status}`) }}
        </b-badge>
      </div>
    </div>
    <div class="d-flex justify-content-center flex-column gap-2">
      <div
        v-if="bookingData.timeHold"
        class="fw-600"
      >
        {{ $t('train.detail.timeHold') }}:
        <span class="text-danger">
          {{ convertISODateTime(bookingData.timeHold).dateTime }}
        </span>
      </div>
      <div
        v-if="bookingData.createdAt"
        class="fw-600"
      >
        {{ $t('train.detail.createdAt') }}:
        {{ convertISODateTime(bookingData.createdAt).dateTime }}
      </div>
    </div>
  </b-card>
</template>
<script>
import { BBadge, BButton, BCard } from 'bootstrap-vue'

import { convertISODateTime } from '@/@core/utils/filter'

import useTrainTicketDetailHandle from '../useTrainTicketDetailHandle'
import { resolveTrainTicketStatusVariant } from '../../useTrainHandle'

export default {
  components: {
    BCard, BButton, BBadge,
  },
  props: {
    bookingData: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const {
      copyText, disabledCopy,
    } = useTrainTicketDetailHandle()

    return {
      copyText,
      disabledCopy,
      resolveTrainTicketStatusVariant,
      convertISODateTime,
    }
  },
}
</script>
<style lang="">

</style>
