var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-footer d-flex-center py-1 px-0 px-lg-4"
  }, [_vm.bookingData ? _c('b-button', {
    staticClass: "px-50 px-md-2 mx-50 mx-md-1",
    attrs: {
      "variant": "warning",
      "pill": ""
    },
    on: {
      "click": function click($event) {
        return _vm.showPopupTicketFace(_vm.bookingData.id);
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap text-truncate"
  }, [_vm._v(_vm._s(_vm.$t('reservation.exportTicket')))])]) : _vm._e(), _vm.bookingData ? _c('b-button', {
    staticClass: "px-50 px-md-2 mx-50 mx-md-1",
    attrs: {
      "variant": "danger",
      "pill": "",
      "disabled": _vm.isDisabledPayment
    },
    on: {
      "click": function click($event) {
        return _vm.openPaymentFlightModalHandle(_vm.bookingData);
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap text-truncate"
  }, [_vm._v(_vm._s(_vm.$t('reservation.payment')))])]) : _vm._e(), _c('ModalPaymentTrainTicket', {
    attrs: {
      "bookingData": _vm.bookingData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }