<template>
  <IAmOverlay :loading="loading">
    <div
      v-if="bookingData"
      class="d-flex flex-column gap-2 p-1"
    >
      <TrainTicketDetailHeader :bookingData="bookingData" />
      <TrainTicketDetailContactInfo :bookingData="bookingData" />
      <TrainTicketDetailTripInfo
        v-if="bookingData.listTrip?.length"
        :bookingData="bookingData"
      />
      <TrainTicketDetailBookingInfo :bookingData="bookingData" />
      <TrainTicketDetailAuditLogs :auditLogs="bookingData?.auditLogs ?? []" />
    </div>
    <TrainTicketDetailFooter :bookingData="bookingData" />
  </IAmOverlay>
</template>

<script>
import router from '@/router'
import IAmOverlay from '@/components/IAmOverlay.vue'

import useTrainTicketDetailHandle from './useTrainTicketDetailHandle'
import TrainTicketDetailFooter from './components/TrainTicketDetailFooter.vue'
import TrainTicketDetailContactInfo from './components/TrainTicketDetailContactInfo.vue'
import TrainTicketDetailBookingInfo from './components/TrainTicketDetailBookingInfo.vue'
import TrainTicketDetailTripInfo from './components/TrainTicketDetailTripInfo.vue'
import TrainTicketDetailHeader from './components/TrainTicketDetailHeader.vue'
import TrainTicketDetailAuditLogs from './components/TrainTicketDetailAuditLogs.vue'

export default {
  components: {
    IAmOverlay,
    TrainTicketDetailFooter,
    TrainTicketDetailContactInfo,
    TrainTicketDetailBookingInfo,
    TrainTicketDetailHeader,
    TrainTicketDetailAuditLogs,
    TrainTicketDetailTripInfo,
  },
  setup() {
    const {
      getBookingById, loading, bookingData, copyText, disabledCopy,
    } = useTrainTicketDetailHandle()

    if (router.currentRoute.params.id) {
      getBookingById(router.currentRoute.params.id)
    }
    return {
      bookingData,
      loading,
      copyText,
      disabledCopy,
    }
  },
}
</script>
<style lang="">
</style>
